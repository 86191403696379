<template>
    <div>
        <ValidationObserver v-slot="{ handleSubmit }">
            <v-form
                    @submit.prevent="handleSubmit(submitQuestion)"
                    method="post"
                    id="check-login-form"
            >
                <v-data-table
                        v-model="question.selected"
                        :headers="headers"
                        :items="question.answers"
                        class="answer-table"
                        :hide-default-footer="true"
                        @input="getSelected"
                >
                    <template class="text-center" v-slot:item.is_correct="{ item }">
                        <input type="checkbox" v-model="item.is_correct">
                    </template>
                    <template v-slot:item.content="{ item }">
                        <div v-if="item.image && item.image.src">
                            <img :src="item.image.src" alt="">
                        </div>
                      <div v-else-if="item.content" v-html="item.content"></div>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon small @click="editItem(item)">mdi-pencil</v-icon>
                        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                    </template>
                </v-data-table>
                <div class="text-end justify-end ma-2">
                    <v-btn @click="openDialog">Thêm đáp án</v-btn>
                </div>
            </v-form>
        </ValidationObserver>
        <v-dialog
                v-model="dialog"
                max-width="500px"
        >
            <v-card>
                <v-card-title>
                    <span class="headline">Nội dung đáp án</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row class="pa-0 ma-0">
                            <v-col
                                    cols="12"
                                    sm="6"
                                    md="12"
                            >
                                <v-select
                                        v-model="answer_type"
                                        :items="answer_types"
                                        item-text="text"
                                        item-value="id"
                                        label="Loại đáp án"
                                        dense
                                        outlined
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row v-show="answer_type===1" class="pa-0 ma-0">
                            <v-col
                                    cols="12"
                                    sm="6"
                                    md="12"
                            >
                                <v-text-field
                                        v-model="answer.content"
                                        outlined
                                        label="Nội dung"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-show="answer_type===2" class="pa-0 ma-0">
                            <v-col
                                    cols="12"
                                    sm="6"
                                    md="12"
                            >
                                <v-file-input
                                        class="pl-1 upload-file-input"
                                        ref="file_input"
                                        id="file_input"
                                        v-model="file"
                                        label="Tải ảnh (kích thước 401x270)"
                                        @change="onChangeFileUpload"
                                ></v-file-input>
                                <div id="preview">
                                    <img v-if="answer.image" :src="answer.image.src"/>
                                </div>
                                <span v-if="imageError" class="validate-error text-danger text-danger">
                  {{ imageError }}
                </span>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="submitAnswer"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

    import {UPLOAD_FILE} from "@/store/exams.module";
    import mixin from "@/store/mixin";

    export default {
        mixins: [mixin],
        props: {
            question: Object,
        },
        data() {
            return {
                file: {},
                answer_types: [
                    {
                        id: 1,
                        text: "Văn bản"
                    },
                    {
                        id: 2,
                        text: "Hình ảnh"
                    }
                ],
                answer_type: 1,
                dialog: false,
                id: -1,
                index: 0,
                editedItem: {
                    content: "",
                },
                headers: [
                    {
                        text: "Chọn đáp án đúng",
                        value: "is_correct",
                    },
                    {text: "Nội dung câu hỏi", value: "content"},
                    {text: "Hành động", value: "actions"},
                ],
                answer: {
                    image: {
                        src: ""
                    },
                    content: "",
                    check: false
                },
                isEdited: false,
                imageError: ""
            };
        },
        methods: {
            clearImage() {

            },
            onChangeFileUpload() {
                let file_input = document.getElementById('file_input');
                let validator = this.validateImage(file_input)
                if (!validator.result) {
                    this.imageError = validator.message;
                    return null;
                }
                let formData = new FormData();
                formData.append("upload", this.file);
                this.$store.dispatch(UPLOAD_FILE, formData).then((data) => {
                    if (data.status) {
                        this.answer.image.src = data.data;
                    }
                });
            },
            getContent(val) {
                this.answer.content = val;
            },
            openDialog() {
                this.dialog = true;
                this.file = {}
                this.answer = {
                    image: {
                        src: ""
                    },
                    content: "",
                    check: false
                }
            },
            editItem(item) {
                this.isEdited = true;
                this.dialog = true;
                this.answer = item;
                if (this.answer.content) {
                    this.answer_type = 1;
                  this.answer.image = {
                    src: ""
                  }
                } else {
                    this.answer_type = 2;
                }
            },
            deleteItem(item) {
                const index = this.question.answers.indexOf(item);
                confirm("Are you sure you want to delete this item?") &&
                this.question.answers.splice(index, 1);
            },
            getSelected() {
                if (this.question.selected[0]) {
                    this.question.answers.forEach((answer) => {
                        answer.is_correct = answer.index === this.question.selected[0].index;
                    });
                }
            },
            submitAnswer() {
              if (this.answer_type == 1) {
                  if (!this.answer.content){
                      this.$toasted.error("Đáp án không được để trống !!", {
                          position: "top-right",
                          duration : 3000
                      })
                      return false
                  }
                  this.answer.image = {
                      src: ""
                  }
              } else {
                  if (!this.answer.image.src){
                      this.$toasted.error("Bạn chưa chọn ảnh !!", {
                          position: "top-right",
                          duration : 3000
                      })
                      return false
                  }
                this.answer.content = ''
              }
                if (this.isEdited) {
                    this.dialog = false;
                    this.isEdited = false;
                } else {
                    this.dialog = false;
                    this.$emit('submitAnswer', this.answer)
                }
            }
            // getQuestionContent(value) {
            //   this.question.content = value;
            // },
        },
    };
</script>

<style lang="scss">
.upload-file-input .v-file-input__text {
  width: 300px!important;
}
    #preview {
        img {
            max-width: 100%;
        }
    }

    .answer-table {
        img {
            max-width: 200px;
        }
    }
</style>
