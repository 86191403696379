<template>
  <v-app>
    <v-container>
      <ValidationObserver ref="create_question" v-slot="{ handleSubmit }">
        <v-form
            @submit.prevent="handleSubmit(submitQuestion)"
            method="post"
            id="check-login-form"
        >
          <!-- index box -->
          <v-row>
            <v-col cols="1">
              <label  class="title-box headline">Index</label></v-col
            >
            <v-col cols="2">
              <ValidationProvider
                  name="index"
                  rules="required|number"
                  v-slot="{ errors }"
              >
                <v-select
                    v-model="question.index"
                    :items="listIndex"
                    dense
                    solo
                    name="name"
                    outlined
                    style="max-width: 80px"
                ></v-select>

                <span class="validate-error text-danger text-danger">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </v-col>
            <v-col cols="9"></v-col>
          </v-row>
          <!-- score box -->
          <!-- question block  -->
          <v-row>
            <v-col cols="12">
              <h5 class="text-title">Nội dung câu hỏi </h5>
              <CkContent
                  :validateMessage="'Nội dung câu hỏi không được bỏ trống !'"
                  :isValidate="!isListeningExam"
                  v-if="question"
                  :content="question.content"
                  name="question"
                  @getData="getData"
              ></CkContent>
            </v-col>
            <!-- question audio  -->
            <v-col v-if="question.audio && isListeningExam " cols="12">
              <ValidationProvider
                  name="audio"
                  v-slot="{ errors }"
              >
                <v-file-input
                    class="pl-1 upload-file-input"
                    id="fileUpload"
                    prepend-icon="mdi-soundcloud"
                    v-model="file"
                    label="Upload File Audio"
                    @click:clear="deleteAudio"
                    @change="onChangeFileUpload"
                ></v-file-input>
                <span class="validate-error text-danger text-danger">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
              <span style="display: block" v-if="audio_message" class="validate-error text-danger text-danger">
                  {{ audio_message }}
                </span>
              <audio v-if="question && question.audio" class="audio-block" ref="audio" controls="controls">
                <source :src="question.audio.src" type="audio/mpeg">
                Your browser does not support the audio element.
              </audio>
            </v-col>
          </v-row>

          <!-- answer block  -->
          <!-- answer block - switch button  -->
          <v-row v-if="!isWriting">
            <v-col cols="12" class="py-2">
              <div class="headline">Thêm đáp án </div>
              <v-btn-toggle
                  v-model="question.question_type"
                  tile
                  color="deep-purple accent-3"
                  @change="changeAnswerComponent"
                  group
              >
                <v-btn v-if="exam_config_id != 5" :value="1"> Chọn đáp án</v-btn>
<!--                <v-divider vertical></v-divider>-->
                <v-btn v-else :value="2"> Điền đáp án</v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <!-- answer block - answer content -->

          <!-- writing instruction block  -->
          <v-row v-show="isWriting">
            <v-col>
              <div class="headline">Nhập đáp án mẫu</div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <component
                  v-bind:is="answerComponent"
                  :question="question"
                  @submitAnswer="submitAnswer"
              ></component>
            </v-col>
          </v-row>
          <!--custom error block  -->
          <v-row class="pa-0 ma-0">
            <v-col class="pa-0 ma-0">
              <div class="pa-10 red--text" v-if="hasError">
                {{ selectError }}
              </div>
            </v-col>
          </v-row>

          <v-row v-if="answerErrorMsg">
            <v-col>
              <p class="validate-error text-danger text-danger">{{ answerErrorMsg }}</p>
            </v-col>
          </v-row>
          <!-- explant answer -->
          <v-row v-if="exam_config_id != 5">
            <v-col cols="12">
              <h5 class="text-title">Giải thích đáp án</h5>
              <CkContent
                  :validateMessage="'Trường giải thích đáp án không được bỏ trống !'"
                  :isValidate="true"
                  v-if="question"
                  :content="question.explanation"
                  name="question"
                  @getData="getData2"
              ></CkContent>
            </v-col>
          </v-row>
          <!-- submit button  -->
          <v-row>
            <v-col>
              <div class="text-center justify-center">
                <div class="my-4">
                  <v-btn
                      class="ma-2 white--text"
                      color="blue-grey"
                      @click="backToQuestionList"
                  >Hủy
                  </v-btn
                  >
                  <v-btn color="primary" type="submit" form="check-login-form"
                  >Lưu
                  </v-btn
                  >
                </div>
              </div>
            </v-col
            >
          </v-row
          >
        </v-form>
      </ValidationObserver>
    </v-container>
  </v-app>
</template>
<script>
import selectAnswer from "@/components/exam-model/select-answer";
import fillAnswer from "@/components/exam-model/fill-answer";
import {mapGetters} from "vuex";
import CkContent from "@/components/ck-content";
import {
  UPLOAD_FILE,
} from "@/store/exams.module";

import {
  DELETE_QUESTION_AUDIO,
  GET_QUESTION_DETAIL
}
  from "@/store/questions.module";

import {
  GET_GROUP_QUESTION_DETAIL
} from "@/store/group-questions.module";

import {
  SAVE_QUESTION,
  UPDATE_QUESTION,
} from "@/store/questions.module";
import {EXAM_CONFIG_ID} from "@/common/constants";
import {SUCCESS} from "@/store/alerts.module";

export default {
  components: {
    selectAnswer,
    fillAnswer,
    CkContent,
    VuetifyAudio: () => import("vuetify-audio"),
  },
  data() {
    return {
      EXAM_CONFIG_ID: EXAM_CONFIG_ID,
      exam_config_id: this.$route.query.exam_config_id,
      group_question_id: this.$route.query.group_question_id,
      question_id: this.$route.query.question_id,
      file: null,
      audio: {},
      selectError: "Bạn phải chọn ít nhất một đáp án ",
      audio_message: "",
      isListenning: false,
      isWriting: false,
      answerComponent: "",
      hasError: false,
      answerErrorMsg: "",
      hasUpload : false,
      listIndex : [],
    }
  },
  computed: {
    ...mapGetters({
      question: "questionDetailForExam",
      group_detail: "group_detail",
    }),
    question: {
      get() {
        return this.$store.getters.questionDetailForExam
      }
      ,
      set(value) {
        this.$store.commit('setQuestionDetail', value)
      }
    },
    isReadingExam() {
      return (this.exam_config_id == EXAM_CONFIG_ID.topicI_reading || this.exam_config_id == EXAM_CONFIG_ID.topicII_reading)
    },
    isListeningExam() {
      return (this.exam_config_id == EXAM_CONFIG_ID.topicI_listening || this.exam_config_id == EXAM_CONFIG_ID.topicII_listening)
    }
  },
  async created() {
    if (this.question_id) {
      await this.$store.dispatch(GET_QUESTION_DETAIL, this.question_id);
    }
    await this.initQuestion();
    let payload = {
      group_question_id : this.$route.query.group_question_id
    }
    await this.$store.dispatch(GET_GROUP_QUESTION_DETAIL, payload);
    if (this.group_detail){
      this.listIndex = this.getIndex(this.group_detail.from, this.group_detail.to)
    } else {
      this.listIndex = this.getIndex(1, 4)
    }
    await this.changeAnswerComponent();
  },
  watch: {
    file: {
      immediate: true,
      handler() {
        if (this.$refs.create_question) {
          this.$refs.create_question.reset();
        }
      }
    },
    audio_message: {
      immediate: true,
      handler(self) {
        self && setTimeout(() => {
          this.audio_message = ""
        }, 2000)
      },
    },
    answerErrorMsg: {
      immediate: true,
      handler(self) {
        self && setTimeout(() => {
          this.answerErrorMsg = ""
        }, 1000)
      },
    } ,
    group_detail(neValue){
      this.listIndex = this.getIndex(neValue.from, neValue.to)
    }
  },

  methods: {
    getIndex(from, to){
      let result = [];
      for (let i = from; i<= to; i++){
        result.push(i);
      }
      return result;
    },
    initQuestion() {
      if (!this.question || (this.question && !this.question.id)) {
        this.question = {
          answers: [],
          fill_answer: {
            content: ""
          },
          content: "",
          explanation:"",
          audio: {
            src: ""
          },
          question_type: 1
        }
      }
      this.question.group_question_id = this.group_question_id
      if (this.question.id && this.isListeningExam) {
        if (!this.question.audio) {
          this.question.audio = {
            src: "",
          }
        } else {
          this.createFileObject(process.env.VUE_APP_API_URL + '/file', this.question.audio.src);
          this.reloadAudio();
        }

      }
    },

    createFileObject(api, src) {
      fetch(api + "?src=" + src)
          .then(res => res.blob()) // Gets the response and returns it as a blob
          .then(blob => {
            this.file = blob;
          });
    },
    submitAnswer(item) {
      this.question.answers.push(item);
      this.$forceUpdate();
    },
    isBlob(file){
      return file instanceof Blob;
    },
    submitQuestion() {
      if (this.exam_config_id != 5) {
        if (this.question.answers.length === 0) {
          this.answerErrorMsg = "Bạn chưa điền đáp án !";
          return null;
        } else if (this.countRightAnswer(this.question.answers) > 1) {
          this.answerErrorMsg = "Bạn không thể chọn nhiều hơn một đáp án đúng !";
          return null;
        } else if (this.countRightAnswer(this.question.answers) < 1) {
          this.answerErrorMsg = "Bạn chưa chọn đáp án đúng !";
          return null;
        }
      } else {
        if (this.question.fill_answer.content == "") {
          this.answerErrorMsg = "Đáp án chưa được nhâp !";
          return null;
        }
      }
      if (!this.question_id) {
        if (this.group_detail) {
          if (this.group_detail.group_question_type_id === 1) {
            if (!this.fileMp3Validation() ) {
              this.audio_message = "File tải lên không đúng định dạng mp3!";
              return null;
            }
            this.$store.dispatch(SAVE_QUESTION, {
              question: this.question,
              exam_config_id: this.exam_config_id,
            }).then((data) => {
              if (data.status) {
                this.backToQuestionList()
              }
            })
          } else {
            this.$store.dispatch(SAVE_QUESTION, {
              question: this.question,
              exam_config_id: this.exam_config_id,
            }).then((data) => {
              if (data.status) {
                this.backToQuestionList()
              }
            })
          }
        } else {
          this.$store.dispatch(SAVE_QUESTION, {
            question: this.question,
            exam_config_id: this.exam_config_id,
          }).then((data) => {
            if (data.status) {
              this.backToQuestionList()
            }
          })
        }
      } else {
        if (this.group_detail) {
          if (this.group_detail.group_question_type_id === 1) {
            if ((!this.fileMp3Validation()) && this.hasUpload) {
              this.audio_message = "File tải lên không đúng định dạng mp3!";
              return null;
            }
            this.$store.dispatch(UPDATE_QUESTION, {
              question: this.question,
              exam_config_id: this.exam_config_id,
            }).then((data) => {
              if (data.status) {
                this.backToQuestionList()
              }
            })
          } else {
            this.$store.dispatch(UPDATE_QUESTION, {
              question: this.question,
              exam_config_id: this.exam_config_id,
            }).then((data) => {
              if (data.status) {
                this.backToQuestionList()
              }
            })
          }
        } else {
          this.$store.dispatch(UPDATE_QUESTION, {
            question: this.question,
            exam_config_id: this.exam_config_id,
          }).then((data) => {
            if (data.status) {
              this.backToQuestionList()
            }
          })
        }
      }
    },
    countRightAnswer(answers) {
      let count = 0;
      answers.forEach((item) => {
        if (item.is_correct) {
          count++;
        }
      })
      return count;
    },
    backToQuestionList() {
      this.$router.push({
        path: "/question/list",
        query: {
          exam_config_id: this.exam_config_id,
          group_question_id: this.group_question_id,
        },
      });
    },
    onChangeFileUpload() {
      this.hasUpload = true;
      if (!this.fileMp3Validation()) {
        this.audio_message = "File tải lên không đúng định dạng mp3!";
        return null;
      }
      let formData = new FormData();
      formData.append("upload", this.file);
      this.$store.dispatch(UPLOAD_FILE, formData).then((data) => {
        if (data.status) {
          if (!this.question.audio) {
            this.question.audio = {
              src: ""
            }
          }
          this.question.audio.src = data.data;
          this.reloadAudio();
        }
      });
      this.isSubmit = true;
    },
    reloadAudio() {
      let vid = this.$refs.audio;
      if (vid) {
        vid.load();
      }
    },
    fileMp3Validation() {
      var fileInput =
          document.getElementById('fileUpload');
      console.log(fileInput,"file upload")
      if(!fileInput){
        return true;
      }
      var filePath = fileInput.value;
      // Allowing file type
      var allowedExtensions =
          /(\.mp3)$/i;
      if (!allowedExtensions.exec(filePath)) {
        fileInput.value = '';
        return false;
      }
      return true;
    },
    getData(value) {
      this.question.content = value;
    },
    getData2(value) {
      this.question.explanation = value;
    },
    changeAnswerComponent() {
      if (this.exam_config_id != 5) {
        this.answerComponent = "selectAnswer";
        this.question.question_type = 1;
      } else {
        this.answerComponent = "fillAnswer";
        this.question.question_type = 2;
      }
    },
    deleteAudio(){
      this.$store.dispatch(DELETE_QUESTION_AUDIO, {
        question : this.question
      }).then((data) => {
        if (data.status) {
          this.$store.dispatch(SUCCESS, {
            type: "success_vote",
            message: "Xóa file thành công"
          }, {
            root: true,
          });
          this.question.audio.src = "";
          this.reloadAudio();
        }
      });
    },
  },

  beforeDestroy() {
    this.question = {}
  }
};
</script>

<style lang="scss">
.upload-file-input {
  width: 250px !important;
}

.audio-block {
  display: block;
}

.error-text {
  color: red;
}
img {
  width: auto;
}
</style>
