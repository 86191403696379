<template>
  <div>
    <v-row>
      <v-col cols="12">
        <CkContent
          v-if="question.fill_answer"
          :content="question.fill_answer.content"
          name="fill-answer"
          @getData="getData"
        ></CkContent>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CkContent from "@/components/ck-content";
export default {
  components: {
    CkContent,
  },
  props: {
    question: Object,
  },
  methods: {
    getData(value) {
      this.question.fill_answer.content = value;
    },
  },
};
</script>
